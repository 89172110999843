import React from 'react'
import cx from 'classnames'
import { useStore, useAddStar } from 'src/context/siteContext'
import { hasStar } from 'src/utils/items'

import {
  Star
} from 'src/components/svgs'

export const MarioStar = ({ starName, className }: { starName: string, className: string }) => {
  const { stars } = useStore()
  const addStar = useAddStar()
  return (
    <div className={cx('abs star z3 top hidden', className, {
      'hidden': hasStar(stars, starName)
    })} onClick={() => addStar(starName)}>
      <Star />
    </div>
  )
}