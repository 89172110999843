import React, { useEffect, useState } from 'react'
import EmblaCarouselReact from 'embla-carousel-react'

import {
  ArrowTick
} from 'src/components/svgs'

import { Image } from 'src/components/image'

export interface InstagramProps {
  data: {
    title?: string
    characterIcon: {
      asset: {
        id: string
      }
    }
    instagrams: Array<{
      asset: {
        _id: string
      }
    }>
  }
}

export const InstagramBlock = ({ title, instagrams }: InstagramProps) => {
  const [embla, setEmblaInsta] = useState(null)
  const [index, setIndex] = useState(0)


  useEffect(() => {
    if (embla) {
      embla.on('select', () => {
        setIndex(embla.selectedScrollSnap())
      })
    }
  }, [embla])

  return (
    <div className='bg-black pb-4 pt-8 instagram'>
      <div className=''>
        <div className="container--xl mxa pl-6 pt-8 800:pt-20 rel al  x">
          <div className='f jcs aie fw'>
            <a className='w-full block' href='https://www.tiktok.com/@offlimits?lang=en' target='_blank' rel='noopener nofollow'>
              <h4 className='offlimits 800:pl-8 text-white text-40 800:text-60 w-full uppercase' dangerouslySetInnerHTML={{ __html: title.replace(/[\r\n]+/g, '') }} />
            </a>
          </div>
        </div>
      </div>
      <div className='rel instagram__slideshow'>
        <div className='container--xl rel mxa'>
          <EmblaCarouselReact
            emblaRef={setEmblaInsta}
            options={{ loop: true, startIndex: 1, align: 'start' }}>
              <div className='x f instagram-slideshow mt1 embla__container'>
                {instagrams.map(({
                  asset
                }, i) => (
                  <div className='instagram__slide p1 embla__slide x ac' key={i}>
                    <a className='x block y' href='https://www.tiktok.com/@offlimits?lang=en' target='_blank' rel='noopener nofollow'>
                      <Image imageId={asset._id} className='x' alt={'instagram image'} />
                    </a>
                  </div>
                ))}
              </div>
            <button aria-label='previous instagram' className='review__arrow  hidden 800:flex abs  items-center justify-center bcy m1 cb' onClick={() => embla.scrollPrev()}><ArrowTick /></button>
            <button aria-label='next instagram' className='review__arrow  hidden 800:flex abs  items-center justify-center bcy last cb m1 right' onClick={() => embla.scrollNext()}><ArrowTick /></button>
          </EmblaCarouselReact>
        </div>
      </div>
    </div>
  )
}