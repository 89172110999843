import React from 'react'
import { Link } from 'gatsby'

export const PageLink = (props: {
  className?: string
  to: string
  type?: string
  ariaLabel?: string
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onMouseOver?: () => void
  children?: any
  external?: boolean
}) => (
  <Link
    type={props.type}
    className={props.className}
    activeClassName='active'
    onClick={props.onClick}
    aria-label={props.ariaLabel}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    onMouseOver={props.onMouseOver}
    target={props.external ? '_blank' : undefined}
    to={props.to}>
    {props.children}
  </Link>
)
