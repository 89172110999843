import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import FocusTrap from 'focus-trap-react'

import { AddButton } from 'src/components/product/addButton'
import BlockContent from "@sanity/block-content-to-react"

import { Serializer } from "src/utils/serializer"

import {motion, AnimatePresence} from 'framer-motion'

import { useKeenSlider } from 'keen-slider/react'
import 'keen-slider/keen-slider.min.css'
import Draggable from 'react-draggable'

import { useCartCount, useToggleCart, useToggleShop, useToggleNav, useStore, useStarsUpdate, usePage } from 'src/context/siteContext'

import { Image } from 'src/components/image'
import { PageLink } from 'src/components/link'
import {
  Logo,
  Close,
  Bowl,
  Star
} from 'src/components/svgs'

const Arrow = (
  <svg width="7" height="12" viewBox="0 0 7 12" fill="none">
    <path d="M1 1.06445L5.94368 6.0142L1 10.9639" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
)

const NavProducts = ({ products, setActive, toggleShop }) => {

  const [slide, setSlide] = useState(1)
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    centered: true,
    slideChanged(s) {
      if (products[s.details().relativeSlide]) {
        setActive(products[s.details().relativeSlide].content.main.colorAssociation)
      }
    },
  })

  return (
    <div className='absolute w-full bottom-0'>
      <button onClick={() => slider.next()} className='bg-transparent absolute z-10 top-1/3 mb-4 left-0 h-14 w-14 border-2 border-solid hover:bg-black hover:text-white border-black rounded-full flex items-center justify-center'>
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
          <path d="M4.83008 7.35547L2.18725 4.7094L4.83008 2.06333" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
      <button onClick={() => slider.prev()} className='bg-transparent absolute z-10 top-1/3 mb-4 right-0 h-14 w-14 border-2 border-solid hover:bg-black hover:text-white border-black rounded-full flex items-center justify-center'>
        <svg width="9" height="9" viewBox="0 0 9 9" fill="none">
          <path d="M4.11035 2.06445L6.75318 4.71052L4.11035 7.35659" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </button>
      <div className='w-full keen-slider overflow-hidden' ref={sliderRef}>
        {products.map(({
            content: {
              main,
              shopify
            }
          }, i) => (
            <div className='w-full p-2 flex justify-center text-center keen-slider__slide' key={i}>
              <div className='w-full'>

                <PageLink onClick={toggleShop}  to={`/products/${main.slug.current}`} className='mr1 s1'>
                  <Image imageId={main.singleImage.asset._id} className='mx-auto w-3/4 1200:w-7/12 max-w-200' alt={`${main.cleanTitle} Cereal`} />
                  <h4 className='text-40 mt-1 uppercase leading-34 text-center text-white offlimits'>{main.cleanTitle}<br />
                    <span className='text-black' style={{marginBottom: '0.45rem' }}>{main.subTitle}</span>
                  </h4>
                </PageLink>
                {/* Add to cart should close the nav? which is sort of confusing? */}

                <AddButton {...shopify} sub={main.subTitle} color={main.colorAssociation} image={main.singleImage.asset._id} />
              </div>
            </div>
        ))}
      </div>
    </div>
  )
}

export const Header = ({ header, hideBar }) => {
  const { customerName, navIsUp, stars, shopAllIsOpen, checkout, starsCheckout, navIsOpen } = useStore()
  const [activeProduct, setActiveProduct] = useState(header && header.productCarousel[0].content.main.colorAssociation)
  const [showStars, setSeeStars] = useState(false)
  const [promoOpen, setPromoOpen] = useState(true)
  const updateCheckoutStars = useStarsUpdate()
  const count = useCartCount()
  const { page } = usePage()
  const toggleShop = useToggleShop()
  const toggleCart = useToggleCart()
  const toggleNav = useToggleNav()

  const promo = header ? header.showPromo ? header.promo : null : null

  useEffect(() => {
    if (stars.length === 10) {
      if (checkout.id && starsCheckout === false) {
        updateCheckoutStars()
      }
    }
  }, [stars, checkout, starsCheckout])

  useEffect(() => {
    if (!promoOpen) {
      const body = document.querySelector('.js-site')
      body.classList.remove('bump-down')
      hideBar()
    }
  }, [promoOpen])

  const focusNav = (

    <AnimatePresence initial={true}>
      {shopAllIsOpen && (
        <motion.div
          className='overflow-hidden fixed top-0 z8 mb-4 border-b-2 box-shadow-sm w-full'
          key={'shop-nav'}
          initial={{  opacity: 0 }}
          animate={{  opacity: 1 }}
          exit={{  opacity: 0}}
          transition={{
            duration: 0.3,
          }}>
            <FocusTrap
              focusTrapOptions={{
                onDeactivate: !shopAllIsOpen,
                clickOutsideDeactivates: true,
                returnFocusOnDeactivate: true
              }}>
              <div className='grid relative z-20 grid-cols-8 w-full '>
                <div className='800:col-span-4 1000:col-span-3 bg-white'>
                  <div className='p-10 1000:p-12'>
                    <div className='pt-24'>
                      <span className='text-18'>Shop</span>
                    </div>
                    <div className='border-t-2 border-dashed border-black mt-4 pt-8 pb-6'>
                      <PageLink onClick={toggleShop}  to='/collections/all' className='text-40 offlimits leading-tight hover:text-orange focus:text-orange flex justify-between items-center uppercase'>
                        <span>Cereal</span>
                        {Arrow}
                      </PageLink>
                    </div>
                    
                    <div className='border-t-2 border-b-2 border-dashed border-black pt-8 pb-6'>
                      <PageLink onClick={toggleShop}  to='/products/cereal-glitter' className='text-40 offlimits leading-tight hover:text-orange focus:text-orange flex justify-between items-center uppercase'>
                        <span>Cereal Glitter</span>
                        {Arrow}  
                      </PageLink>
                    </div>
                    <div className='border-t-2 border-dashed border-black pt-8 pb-6 opacity-0 pointer-events-none'>
                      <PageLink onClick={toggleShop}  to='/collections/all' className='text-40 offlimits leading-tight hover:text-orange focus:text-orange flex justify-between items-center uppercase'>
                        <span>Variety Packs</span>
                        {Arrow}  
                      </PageLink>
                    </div>
                  </div>
                </div>
                <div className={cx('col-span-4 1000:col-span-5 hidden 800:block border-l-2 duration-200 border-solid', activeProduct || 'bg-black')}>
                  <div className='p-6 h-full flex items-center justify-center w-full'>
                    <div className='pt-30 h-full relative max-w-400 w-full'>
                      {header && header.productCarousel && (
                        <NavProducts products={header.productCarousel} toggleShop={toggleShop} setActive={product => setActiveProduct(product)} />
                      )}
                    </div>
                  </div>
                </div>
                <div className='800:col-span-4 1000:col-span-3 relative bg-black text-white hidden'>
                  <div className='h-full w-full block'>
                    <div className='absolute bottom-0 w-full'>
                      <div className='pt-30'>
                        <img src='/variety-group.png' className='w-full max-w-400 mx-auto' alt='variety pack of cereals' />
                      </div>
                      <div className='pt-8 pl-6 pr-6 pb-8'>
                        <div className='text-center max-w-300 mx-auto'>
                          <h3 className='text-40 leading-34 offlimits uppercase'>Build your Own Variety Pack</h3>
                          <p className='text-24 leading-tight mb-4'>Mix and match your favorites with no limits.</p>
                          <PageLink onClick={toggleShop} to='/build-a-variety-pack' className='p-2 w-full border-2 block hover:border-white hover:bg-white hover:text-black duration-200 border-current border-solid rounded-md text-16 border-current text-current'>Make your own</PageLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FocusTrap>
            <div onClick={toggleShop} className='bg-white bg-opacity-5 fixed w-full h-full top left z-10' />
          </motion.div>
      )}
    </AnimatePresence>
  )

  return (
    <div className={cx('header__wrapper', {'show-mobile-nav': navIsOpen})}>

      {showStars && (
        <Draggable
          defaultPosition={{x: 10, y: 100}}>
          <div className='popup__stars fix z3 bcy box-shadow ba'>
            <button type='reset' className='bcy abs draggable__close p05 bab z2 cb' onClick={() => setSeeStars(false)}>
                <Close className='block' />
              </button>
            <div className={`how__popup-header  p1 bcp f jcb aic js-header-1`}>
              <span className='s24'>Seeing Stars?</span>
             
            </div>
            <div className='p1 rel bcb ac cw'>
              <img src='/star_bg-small.png' className='abs x popup__stars-bg y top left' />
              <div className='popup__stars-star rel z1 f jcc aic'>
                <Star />
              </div>
              <p className='rel z1'>Find all 10 Stars floating around the site and get 100 additional tickets</p>
            </div>
          </div>
        </Draggable>
      )}
      <header className={cx('header w-full bcw fix top-0 z9 x', page, {
        'hide-nav': navIsUp,
        'is-promo': promoOpen && promo,
        'show-mobile-nav': navIsOpen
      })}>
        {promo && promoOpen && (
          <div className='bg-black mono text-14 uppercase relative text-white text-center pb-1 pl-2 pr-2'>
            <BlockContent blocks={promo} serializers={Serializer} />
            <button onClick={() => setPromoOpen(false)} className='absolute top right h-full mr-4 flex items-center justify-center text-white'>
              <Close />
            </button>
          </div>
        )}
        <div className='w-full mxa pl-5 pr-5 pt-2 pb-2 800:p-5 1000:pl-12 1000:pr-12 text-18 outer'>
          <div className='f x jcb aic'>
            <div className='header__mobile-toggle s-mobile'>
              <button onClick={toggleNav} aria-label='menu' className='f bctran jcc aic p0'><span className='block header__mobile-lines' /></button>
            </div>
            <div className='header__nav sf-desktop jcs aic'>
            <PageLink to='/collections/all' className='mr1 s1'>
            Shop
            </PageLink> 
              <a href='https://storerocket.io/embed/Yw8lORjJvo' target='_blank' className='mr1 s1'>Store Locator</a>
              <a href='mailto:sales@eatofflimits.com' target='_blank' className='mr1 s1'>Wholesale</a>

              {/* <PageLink to='/gallery' className='mr1 s1'>Gallery</PageLink> */}
            </div>
            <PageLink to='/' ariaLabel='home' className='abs  header__logo z2'>
              <Logo />
            </PageLink>
            <div className='header__bowl flex items-center'> 

            <PageLink to='/about' className='mr1 s1'>About Us</PageLink>
              <button onClick={() => setSeeStars(true)} className='s1 header__star hidden items-center mr1'><Star className='mr05' />{stars.length}/10</button>
              <button aria-label='cart' className='p0 header__bowl-button flex s1' onClick={toggleCart}>
                <Bowl className='rel' /> <span className='inline-block ml-2 s1'>{count}</span>
              </button>
            </div>
          </div>
        </div>
      </header>
      {focusNav}
      <div className='header__mobile overflow-y-scroll bg-black fix y z7 w-full top left'>
        <div className='bg-gray p-6 w-full h-full'>
          <div className='pt-10 hidden'>
            <span className='text-18'>Shop</span>
          </div>
          <div className='border-t-2 border-dashed border-black mt-4 pt-2 pb-2 hidden'>
            <PageLink to='/collections/all' onClick={toggleNav} className='text-30 offlimits flex justify-between items-center uppercase'>
              <span>Cereal</span>
              {Arrow}
            </PageLink>
          </div> 
          <div className='border-t-2 border-b-2 border-dashed border-black pt-2 pb-2 hidden'>
            <PageLink to='/products/cereal-glitter'onClick={toggleNav}  className='text-30 offlimits flex justify-between items-center uppercase'>
              <span>Cereal Glitter</span>
              {Arrow}  
            </PageLink>
          </div>
          <div className='grid grid-cols-2 pt-6 pb-6'>
            <div>
            <PageLink to='/collections/all' onClick={toggleNav}  className='block text-20'>Shop</PageLink>
              <PageLink to='/about' onClick={toggleNav}  className='block text-20'>About Us</PageLink>
              <a href='https://storerocket.io/embed/Yw8lORjJvo' target='_blank' className='block text-20'>Store Locator</a>
              <a href='mailto:sales@eatofflimits.com' target='_blank' className='block text-20'>Wholesale</a>
            </div>              

            <div> 
              <PageLink to='/faq'onClick={toggleNav}  className='block text-20'>FAQ</PageLink>
            </div>
          </div>
        </div>
        <div className='bg-black text-white p-6 hidden'>
          <div className=''>
            <img src='/variety-group.png' className='w-full' alt='variety pack of cereals' />
          </div>
          <div className='pt-4 pl-6 pr-6 pb-10'>
            <div className='text-center max-w-300 mx-auto'>
              <h3 className='text-40 offlimits uppercase'>Build your Own Variety Pack</h3>
              <p className='text-24 leading-tight'>Mix and match your favorites with not limits.</p>
              <PageLink to='/build-a-variety-pack' onClick={toggleNav} className='p-2 w-full border-2 block hover:border-white hover:bg-white duration-200 border-current border-solid rounded-md text-16 border-current text-current'>Make your own</PageLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}