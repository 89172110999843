import React, { useState } from 'react'

import {subscribe} from "klaviyo-subscribe"

import {
  Arrow
} from 'src/components/svgs'

export const Newsletter = ({ arrowClass }: { arrowClass: string}) => {
  const [success, setSuccess] = useState(false)
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    const form = e.currentTarget
    const { email } = form.elements
    subscribe('HzeFJf', email.value)
    .then(() => {
      form.reset()
      setSuccess(true)
    })
  }
  return (
    <React.Fragment>
      {!success ? (
        <form onSubmit={e => handleSubmit(e)}  className="newsletter founder x mxa rel" data-component="form">
          <div className='js-wrapper '>
            <input aria-label='user email' required={true} className="py1 founders x" type="email" name="email" placeholder='Your Email' />
            <button aria-label='signup' type="submit" className="abs y top right f jcc aic">
              <Arrow className={arrowClass} />     
            </button>
          </div>
          <div className='js-message hidden'>
            <span className="founders">Well, aren't you an early-bird? Be in touch soon!</span>
          </div>
        </form>
      ) : (
        <div>Got it!</div>
      )}

    </React.Fragment>
  )
}