// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-happybirthday-tsx": () => import("./../src/pages/happybirthday.tsx" /* webpackChunkName: "component---src-pages-happybirthday-tsx" */),
  "component---src-pages-previews-tsx": () => import("./../src/pages/previews.tsx" /* webpackChunkName: "component---src-pages-previews-tsx" */),
  "component---src-templates-collection-tsx": () => import("./../src/templates/collection.tsx" /* webpackChunkName: "component---src-templates-collection-tsx" */),
  "component---src-templates-page-tsx": () => import("./../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-product-tsx": () => import("./../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---src-templates-toy-store-tsx": () => import("./../src/templates/toy-store.tsx" /* webpackChunkName: "component---src-templates-toy-store-tsx" */)
}

