import React from "react"
import cx from 'classnames'
import { Link } from 'gatsby'
import BlockContent from "@sanity/block-content-to-react"

import { AddButton } from 'src/components/product/addButton'

const handleLink = (appLink, reference) => {
  if (appLink) {
    return appLink
  } else {
    if (reference) {
      switch (reference._type) {
        case 'product': 
          return `/products/${reference.content.main.slug.current}`
        case 'collection':
          return `/collections/${reference.content.main.slug.current}`
        default:
          return `/${reference.content.main.slug.current}`
      }
      // return reference._type === 'product' ? '/products' : '/' + reference.content.main.slug.current
    } else {
      return '/'
    }
  }
}

export const Serializer = {
  types: {
    product: (props: {
      children: any
      node: any
    }) => {
      return (
        <AddButton buttonColor='text-current border-current button--md mt-6 w-full pr-5 pl-5' color={props.node.content.main.colorAssociation} {...props.node.content.shopify} sub={props.node.content.main.subTitle} image={props.node.content.main.singleImage.asset._ref} />
    )},
    block: (props: {
      children: any
      node: any
    }) => {
      const { style } = props.node
      if (style === 'h1') {
        return <h1 className='text-48 leading-40 800:text-60 800:leading-60 1200:text-70 1200:leading-60  offlimits mb-8 uppercase'>{props.children}</h1>
      } else if (style === 'h1-shadow') {
        return <h1 className='text-48 leading-40 800:text-60 800:leading-60 1200:text-70 1200:leading-60  offlimits text-shadow text-white mb-8 uppercase'>{props.children}</h1>
      } else if (style === 'h2') {
        return <h2 className="text-48 mb-4 800:text-60 leading-40 800:leading-60 offlimits uppercase">{props.children}</h2>
      } else if (style === 'h3') {
        return <h3 className='text-24 800:text-40 leading-tighter '>{props.children}</h3>
      } else if (style === 'h4') {
        return <h4 className='text-21 800:text-30 leading-tight offlimits uppercase'>{props.children}</h4>
      } else if (style === 'h5') {
        return <h5 className='text-18 800:text-24 leading-tight offlimits uppercase'>{props.children}</h5>
      } else if (style === 'badge') {
        return (<div className='text-center mt-2 mb-8'><h5 className='mono text-14 rounded-sm mx-auto text-center bg-yellow inline-block text-black uppercase p-1 pl-4 pr-4'>{props.children}</h5></div>)
      }else if (style === 'normal') {
        return (<p className='text-18'>{props.children}</p>)
      }else if (style === 'normal-large') {
        return (<p className='text-20 large__text mb-3 800:mb-4 800:text-24'>{props.children}</p>)
      }
      if (/offlimits/.test(style)) {
        return <h4 className='cw offlimits s40 shadow uppercase'>{props.children}</h4>
      }
      return BlockContent.defaultSerializers.types.block(props)
    },
    
  },
  marks: {

    flex: ({children}) => <span className="offlimits text-blue uppercase">{children}</span>,
    spark: ({children}) => <span className="offlimits text-pink uppercase">{children}</span>,
    zombie: ({children}) => <span className="offlimits text-zombie uppercase">{children}</span>,
    dash: ({children}) => <span className="offlimits text-yellow uppercase">{children}</span>,
    alignLeft: ({children}) => <span className="block text-left">{children}</span>,
    alignCenter: ({children}) => <span className="block text-center">{children}</span>,
    alignRight: ({children}) => <span className="block text-right">{children}</span>,
    serif: (props: {
      children: any
    }) => (
      <span className='cooper'>{props.children}</span>
    ),

    internalButton: (props: {
      children: any
      mark: {
        appLink?: string
        buttonType?: string
        reference?: {
          content: {
            main: {
              slug: {
                current: string
              }
            }
          }
        }
      }
    }) => {
      const {
        appLink,
        buttonType,
        reference
      } = props.mark
      if (appLink && /mailto/.test(appLink)) {
        return <a className='underline-extra ls1 caps s14 founders bold' href={appLink}>{props.children}</a>
      } else {
        return <Link className={cx('', {
          'button box-shadow-sm ac button--red text-black button--md mt-8 block pl-8 pr-8 s20 w-auto button--border': buttonType === 'button',
          'button box-shadow-sm ac button--black text-white s32 offlimits uppercase button--md mt-8 block pl-8 pr-8  w-auto button--border': buttonType === 'big-black',
          'button box-shadow-sm ac button--black text-white button--md mt-8 block pl-8 pr-8 s20 w-auto button--border': buttonType === 'button-black',
          'underline-extra ls1 caps s14 founders bold': buttonType === 'underline'
        })} to={handleLink(appLink, reference)}>{props.children}</Link>
      }
    }
  }
}
