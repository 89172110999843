{
  /*
  Orginal Author of this file: https://github.com/thetrevorharmon
  Orginal File: https://github.com/thetrevorharmon/sell-things-fast/blob/master/src/context/StoreContext.js
*/
}

import React, { useState, useEffect, useContext } from "react";
import { Checkout, CheckoutErrorCode } from "shopify-storefront-api-typings";
import ShopifyClient from "shopify-buy";
import cookie from "js-cookie";
import sanityClient from "@sanity/client";
import { decode } from "shopify-gid";
import { getAttribute } from "src/utils/items";
import queryString from "query-string";

import { shopify as storefront, globalID } from "src/shopify/client";
import {
  cartCreateQuery,
  cartQuery,
  cartLinesAddQuery,
  cartLinesUpdateQuery
} from "src/shopify/queries";

const OFFLIMIT_STARS = "shopify_stars";
const SHOPIFY_CART_KEY = "shopify_cart_id";

const client = ShopifyClient.buildClient({
  storefrontAccessToken: "54cb6d2a342b55e87ae761c8bd845bea",
  domain: "shop.eatofflimits.com"
});

const sanity = sanityClient({
  projectId: "0z8flqaa",
  dataset: "production",
  useCdn: false // `false` if you want to ensure fresh data
});

interface RechargeItem {
  customAttributes: any[];
  quantity: number;
  variant: {
    id: string;
  };
}

interface RechargeSubscription {
  value: string;
}

interface InitialStore {
  client: ShopifyClient;
  isAdding: boolean;
  cartIsOpen: boolean;
  navIsOpen: boolean;
  navIsUp: boolean;
  shopAllIsOpen: boolean;
  page: undefined;
  sanity: sanityClient;
  stars: any[];
  orders: any[];
  toys: any[];
  products: any[];
  cartToys: any[];
  starsCheckout: boolean;
  customerEmail: string | undefined;
  customerName: string | undefined;
  customerId: string | undefined;
  customerToken: string | undefined;
  customerTickets: number;
  checkout: Checkout;
  rechargeCustomer: any;
}

const initialStoreState = {
  client,
  sanity,
  isAdding: false,
  cartIsOpen: false,
  shopAllIsOpen: false,
  customerEmail: undefined,
  customerName: undefined,
  customerId: undefined,
  customerToken: undefined,
  customerTickets: 0,
  stars: [],
  starsCheckout: false,
  toys: [],
  products: [],
  cartToys: [],
  page: null,
  navIsOpen: false,
  navIsUp: false,
  rechargeCustomer: undefined,
  checkout: {
    lineItems: []
  } as Checkout
};

const StoreContext = React.createContext({
  store: initialStoreState,
  setStore: () => null
});

const createCart = async () => {
  const response = await storefront.fetch(cartCreateQuery, {
    input: {
      lines: [],
      note: ""
    }
  });

  const cart = response?.cartCreate?.cart;

  return cart;
};

const getCart = async (id: string) => {
  const response = await storefront.fetch(cartQuery, {
    id
  });

  console.log(response);

  // Cart Object from Storefront API
  let cart = response?.cart;

  return cart;
};

const fetchAllProducts = () => {
  // Mount our products into the dom for inventory checking
  client.product.fetchAll(40).then(products => {
    window.PRODUCTS = products;
    // Create a split out array of all variants
    const variantInvetory = [];
    products.forEach(product => {
      product.variants.forEach(variant => {
        const smartVariant = {
          title: product.title,
          id: parseInt(decode(variant.id).id, 10),
          available: variant.available
        };
        variantInvetory.push(smartVariant);
      });
    });
    window.PRODUCT_INVENTORY = variantInvetory;
  });
};

const setCartState = (cart, setStore: any) => {
  console.log("updated cart context", cart.id);
  const isBrowser = typeof window !== "undefined";

  if (isBrowser) {
    localStorage.setItem(SHOPIFY_CART_KEY, cart?.id);
  }

  setStore((prevState: InitialStore) => {
    return { ...prevState, cart };
  });
};

const setStarsInState = (stringStars: any, setStore: any) => {
  setStore((prevState: InitialStore) => {
    return { ...prevState, stars: JSON.parse(stringStars) };
  });
};

const initCustomer = async (setStore: any) => {
  const customerEmail = cookie.get("customer_email");
  const customerToken = cookie.get("customer_token");
  const customerName = cookie.get("customer_firstName");
  const customerId = cookie.get("customer_id");

  let rechargeCustomer = {};
  if (customerId) {
    await fetch(
      `/.netlify/functions/recharge-customer?customerId=${customerId}`,
      { method: "GET" }
    )
      .then(res => res.json())
      .then(res => (rechargeCustomer = res ? res : {}));
  }

  if (customerEmail && customerToken && customerName) {
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        customerEmail,
        customerId,
        customerToken,
        customerName,
        rechargeCustomer
      };
    });
  }
};

const initTickets = (setStore: any) => {
  const customerId = cookie.get("customer_id");

  if (customerId) {
    const queryUser = `*[_type == "customer" && _id == "${customerId}"] {
      ...
    }`;

    sanity.fetch(queryUser).then((res: any) => {
      if (res.length > 0) {
        const customerTickets = res[0].content.main.tickets;
        setStore((prevState: InitialStore) => {
          return { ...prevState, customerTickets };
        });
      }
    });
  }
};

const fetchProducts = (setStore: any) => {
  const query = '*[_type == "product"]';
  sanity.fetch(query).then((products: any) => {
    if (products.length > 0) {
      setStore((prevState: InitialStore) => {
        return { ...prevState, products };
      });
    }
  });
};

const fetchToyStore = (setStore: any) => {
  const queryToys = `*[_type == "toyStore"] {
    ...,
    content {
      cartToys {
        ...,
        toys[]-> {
          ...,
          content {
            ...,
            main {
              ...,
              'image': mainImage.asset->url
            }
          }
        }
      },
      accountToys {
        ...,
        toys[]-> {
          ...,
          content {
            ...,
            main {
              ...,
              'image': mainImage.asset->url
            }
          }
        }
      }
    }
  }`;
  sanity.fetch(queryToys).then((res: any) => {
    if (res.length > 0) {
      const toys = res[0].content.accountToys.toys;
      const cartToys = res[0].content.cartToys.toys;
      setStore((prevState: InitialStore) => {
        return { ...prevState, toys, cartToys };
      });
    }
  });
};

const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(initialStoreState);
  const [initStore, setInitStore] = useState(false);

  useEffect(() => {
    if (initStore === false) {
      const initializeCheckout = async () => {
        // Check for an existing cart.
        const isBrowser = typeof window !== "undefined";
        const existingCartId = isBrowser
          ? localStorage.getItem(SHOPIFY_CART_KEY)
          : null;

        if (existingCartId) {
          console.log("fetching...", existingCartId);

          try {
            const cart = await getCart(existingCartId);

            // Make sure this cart hasn’t already been purchased.
            if (cart) {
              setCartState(cart, setStore);
              return;
            } else {
              const newCart = await createCart();

              return setCartState(newCart, setStore);
            }
          } catch (e) {
            console.log(e);
            localStorage.delete(SHOPIFY_CART_KEY);

            const newCart = await createCart();

            return setCartState(newCart, setStore);
          }
        } else {
          const newCart = await createCart();
          console.log("creating cart...", newCart);

          setCartState(newCart, setStore);
        }
      };
      const initializeStars = async () => {
        const isBrowser = typeof window !== "undefined";
        const existingStars = isBrowser
          ? localStorage.getItem(OFFLIMIT_STARS)
          : null;
        if (existingStars) {
          setStarsInState(existingStars, setStore);
        }
      };
      initCustomer(setStore);
      initTickets(setStore);
      fetchToyStore(setStore);
      fetchProducts(setStore);
      initializeCheckout();
      initializeStars();
      setInitStore(true);
    }
  }, [store, setStore, store.client.checkout, initStore]);

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

function useStore() {
  const { store } = useContext(StoreContext);
  return store;
}

function useCartCount() {
  const {
    store: { cart }
  } = useContext(StoreContext);

  return cart?.totalQuantity || 0;
}

function useCartTickets() {
  const {
    store: { checkout }
  } = useContext(StoreContext);

  let cartTickets = 0;
  // FIXME: Hide cart tickets for now
  // if (checkout.lineItems) {
  //   cartTickets = checkout.lineItems.reduce(
  //     (runningTotal: number, item: any) => parseInt(item.customAttributes[2].value * item.quantity, 0) + runningTotal,
  //     0
  //   )
  // }

  return cartTickets;
}

const setCustomerInState = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  async function updateCustomerInState() {
    const customerEmail = cookie.get("customer_email");
    const customerToken = cookie.get("customer_token");
    const customerId = cookie.get("customer_id");
    const customerName = cookie.get("customer_firstName");
    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        customerEmail,
        customerToken,
        customerId,
        customerName
      };
    });
  }

  return updateCustomerInState;
};

const useTickets = () => {
  const { setStore } = useContext(StoreContext);

  async function updateCustomerTickets() {
    const customerId = cookie.get("customer_id");

    if (customerId) {
      const queryUser = `*[_type == "customer" && _id == "${customerId}"] {
        ...
      }`;

      sanity.fetch(queryUser).then((res: any) => {
        if (res.length > 0) {
          const customerTickets = res[0].content.main.tickets;
          setStore((prevState: InitialStore) => {
            return { ...prevState, customerTickets };
          });
        }
      });
    }
  }

  return updateCustomerTickets;
};

const useRechargeCustomer = () => {
  const { setStore }: { setStore: any } = useContext(StoreContext);

  async function updateRechargeCustomer() {
    const customerId = cookie.get("customer_id");
    let rechargeCustomer = {};
    if (customerId) {
      // FIXME: server response issue, this doesn't actually work because the customer id is not what recharge wants here
      await fetch(
        `/.netlify/functions/recharge-customer?customerId=${customerId}`,
        { method: "GET" }
      )
        .then(res => res.json())
        .then(res => (rechargeCustomer = res ? res[0] : {}));
    }

    if (rechargeCustomer) {
      setStore((prevState: InitialStore) => {
        return { ...prevState, rechargeCustomer };
      });
    }
  }

  return updateRechargeCustomer;
};

function usePage() {
  const {
    store: { page }
  } = useContext(StoreContext);

  return {
    page
  };
}

function useCartTotals() {
  const {
    store: { cart }
  } = useContext(StoreContext);

  const cost = cart?.cost;

  const tax = cost?.totalTaxAmount
    ? `${Number(cost?.totalTaxAmount.amount).toFixed(2)}`
    : "-";
  const total = cost?.subtotalAmount
    ? `${Number(cost?.subtotalAmount.amount).toFixed(2)}`
    : "-";

  return {
    tax,
    total
  };
}

function useCustomer() {
  const {
    store: { customerEmail, customerName, customerId, customerToken }
  } = useContext(StoreContext);

  return { customerEmail, customerName, customerId, customerToken };
}

function useCartItems() {
  const {
    store: { cart }
  } = useContext(StoreContext);

  console.log(cart);

  return cart?.lines?.edges || [];
}

function useAddItemToCart() {
  const {
    store: { cart },
    setStore
  }: { store: InitialStore; setStore: any } = useContext(StoreContext);

  async function addItemToCart(
    variantId: string,
    quantity: number,
    attributes?: []
  ) {
    if (variantId === "" || !quantity) {
      console.error("Both a size and quantity are required.");
      return;
    }

    const cartId = cart.id;
    const lineItemsToAdd = [{ merchandiseId: variantId, quantity, attributes }];

    const { cartLinesAdd } = await storefront.fetch(cartLinesAddQuery, {
      cartId,
      lines: lineItemsToAdd
    });

    const newCart = cartLinesAdd.cart;

    setCartState(newCart, setStore);

    return setStore((prevState: InitialStore) => {
      return { ...prevState, cartIsOpen: true };
    });
  }

  return addItemToCart;
}

function useAddItemsToCart() {
  // @ts-ignore
  const {
    store: { checkout, client },
    setStore
  }: { store: InitialStore; setStore: any } = useContext(StoreContext);

  async function addItemsToCart(
    lineItems: Array<{
      variantId: string;
      quantity: number;
      customAttributes?: [];
    }>
  ) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, isAdding: true };
    });

    const checkoutId = checkout.id;

    const newCheckout = await client.checkout.addLineItems(
      checkoutId,
      lineItems
    );

    setStore((prevState: InitialStore) => {
      return {
        ...prevState,
        checkout: newCheckout,
        cartIsOpen: true,
        isAdding: false
      };
    });
  }

  return addItemsToCart;
}

function useStarsUpdate() {
  const {
    store: { checkout },
    setStore
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function updateCheckoutStars() {
    const checkoutId = checkout.id;

    const customAtt = {
      customAttributes: [{ key: "starValue", value: "100" }]
    };
    const newCheckout = await client.checkout.updateAttributes(
      checkoutId,
      customAtt
    );

    setStore((prevState: InitialStore) => {
      return { ...prevState, checkout: newCheckout, starsCheckout: true };
    });
  }

  return updateCheckoutStars;
}

function useAddStar() {
  const {
    store: { stars },
    setStore
  }: { store: InitialStore; setStore: any } = useContext(StoreContext);
  const newStars = stars;
  async function addStar(starId: string) {
    newStars.push({ key: starId, value: true });

    localStorage.setItem(OFFLIMIT_STARS, JSON.stringify(newStars));
    setStore((prevState: InitialStore) => {
      return { ...prevState, stars: newStars };
    });
  }

  return addStar;
}

function useRemoveItemFromCart() {
  const {
    store: { checkout },
    setStore
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function removeItemFromCart(itemId) {
    console.log("remove?", checkout);
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      itemId
    ]);

    setStore((prevState: InitialStore) => {
      return { ...prevState, checkout: newCheckout };
    });
  }

  return removeItemFromCart;
}

function useUpdateItemsFromCart() {
  const {
    store: { cart },
    setStore
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function updateItemsFromCart(items: any) {
    items = [].concat(items);

    const { cartLinesUpdate } = await storefront.fetch(cartLinesUpdateQuery, {
      cartId: cart.id,
      lines: items
    });

    const newCart = cartLinesUpdate.cart;

    return setCartState(newCart, setStore);
  }

  return updateItemsFromCart;
}

function useRecharge() {
  const {
    store: { checkout }
  }: {
    store: InitialStore;
  } = useContext(StoreContext);

  const createPropertiesArray = (customProperties: any) => {
    const obj = {};
    customProperties.forEach(
      (property: any) => (obj[property.key] = property.value)
    );
    return obj;
  };

  const createSubscriptionItem = (
    item: RechargeItem,
    sub: RechargeSubscription
  ) => {
    const { quantity, variant, customAttributes } = item;
    const subcscription = JSON.parse(sub.value);
    // @ts-ignore
    const variant_id = parseInt(decode(variant.id).id, 10);
    return {
      charge_interval_frequency: subcscription.chargeIntervalFrequency,
      order_interval_frequency: subcscription.chargeIntervalFrequency,
      order_interval_unit: subcscription.orderIntervalUnit,
      variant_id,
      quantity,
      properties: createPropertiesArray(customAttributes)
    };
  };

  const createOneTimeItem = (item: RechargeItem) => {
    const { quantity, variant, customAttributes } = item;
    // @ts-ignore
    const variant_id = parseInt(decode(variant.id).id, 10);
    return {
      quantity,
      variant_id,
      properties: createPropertiesArray(customAttributes)
    };
  };

  return async () => {
    const lineItems = checkout.lineItems.map((item: RechargeItem) => {
      const subcscription = getAttribute(
        item.customAttributes,
        "_isSubscription"
      );
      return subcscription
        ? createSubscriptionItem(item, subcscription)
        : createOneTimeItem(item);
    });
    fetch(`/.netlify/functions/recharge-checkout`, {
      method: "POST",
      body: JSON.stringify({
        line_items: lineItems,
        external_checkout_id: checkout.id,
        note_attributes: [{ name: "checkoutId", value: checkout.id }]
      })
    })
      .then(res => res.json())
      .then(res => {
        try {
          var ga_linker = ga.getAll()[0].get("linkerParam");
        } catch (err) {
          var ga_linker = "";
        }
        const token = res.checkout.token;
        window.location.href = `https://checkout.rechargeapps.com/r/checkout/${token}?myshopify_domain=offlimits-cereal.myshopify.com&${ga_linker}`;
      });
  };
}

function useCheckout() {
  const {
    store: { checkout }
  }: {
    store: InitialStore;
  } = useContext(StoreContext);

  return () => {
    window.location.href = checkout.webUrl;
  };
}

function useSetPage() {
  const {
    setStore
  }: {
    setStore: any;
  } = useContext(StoreContext);
  async function setPage(page: string) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, page };
    });
  }
  return setPage;
}

function useSetNavIsUp() {
  const {
    setStore
  }: {
    setStore: any;
  } = useContext(StoreContext);
  async function setNavIsUp(navIsUp: boolean) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, navIsUp };
    });
  }
  return setNavIsUp;
}

function useToggleCart() {
  const {
    store: { cartIsOpen },
    setStore
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function toggleCart() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, cartIsOpen: !cartIsOpen };
    });
  }

  return toggleCart;
}

function useToggleShop() {
  const {
    store: { shopAllIsOpen },
    setStore
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function toggleShop() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, shopAllIsOpen: !shopAllIsOpen };
    });
  }

  return toggleShop;
}

function useToggleNav() {
  const {
    store: { navIsOpen },
    setStore
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function toggleNav() {
    setStore((prevState: InitialStore) => {
      return { ...prevState, navIsOpen: !navIsOpen };
    });
  }

  return toggleNav;
}

function useUpdateTickets() {
  const {
    store,
    setStore
  }: {
    store: InitialStore;
    setStore: any;
  } = useContext(StoreContext);

  async function updateTickets(customerTickets: number) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, customerTickets };
    });
  }

  return updateTickets;
}

export {
  StoreContextProvider,
  fetchAllProducts,
  useAddItemToCart,
  useStore,
  usePage,
  useStarsUpdate,
  useSetPage,
  useSetNavIsUp,
  useAddItemsToCart,
  setCustomerInState,
  useCustomer,
  useRechargeCustomer,
  useTickets,
  useCartTickets,
  useCartCount,
  useCartItems,
  initCustomer,
  initTickets,
  useCartTotals,
  useAddStar,
  useToggleNav,
  useToggleShop,
  useUpdateItemsFromCart,
  useRemoveItemFromCart,
  useCheckout,
  useToggleCart,
  useRecharge,
  useUpdateTickets
};
