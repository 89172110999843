import Analytics from "analytics";
import googleTagManager from "@analytics/google-tag-manager";
import googleAnalytics from "@analytics/google-analytics";
import originalSrc from "analytics-plugin-original-source";

import { ShopifyCart } from "src/utils/shopifyTypes";

export interface GTMProduct {
  name: string; // ShopifyLineItem product title
  id: string; // SKU!
  price: number; // string
  variant: string; // ShopifyLineItem variant title
  trackingPosition?: number | string;
  brand?: string; // "Offlimits" --> leaving as optional to maybe override some day
  category?: string; // "All"
}

export const analytics = Analytics({
  app: "offlimits",
  plugins: [
    googleTagManager({
      containerId: "GTM-K64KZBJ"
    }),
    originalSrc()
  ]
});

// Event for tracking Page Views
// Extends analytics.page to provide a named event for GTM
export const trackPageView = (title = "") => {
  analytics.page({
    title,
    event: "viewPage"
  });
};

// Event for tracking Views of Product Impressions
// Follows Google's Enhanced Ecommerce: https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
// Also includes generic event information
export const trackProductImpression = (
  products: GTMProduct[],
  list: string
) => {
  analytics.track("product_impression", {
    ecommerce: {
      impressions: products.map(product => ({
        ...product,
        brand: "Offlimits",
        category: "All",
        list
      }))
    },
    eventDetail: {
      category: "Core Ordering",
      action: "Product Impression",
      label: products.map(product => product.name).join(", ")
    }
  });
};

// Event for tracking Views of Product Clicks
// Follows Google's Enhanced Ecommerce: https://developers.google.com/tag-manager/enhanced-ecommerce#product-impressions
// Also includes generic event information
export const trackProductClick = (
  products: GTMProduct[],
  list: string,
  position: number,
  additionalDetails?: { [propName: string]: any }
) => {
  analytics.track("product_clicked", {
    ecommerce: {
      click: {
        actionField: { list: "" }
      },
      products: products.map(product => ({
        ...product,
        brand: "Offlimits",
        category: "All",
        list,
        position
      }))
    },
    eventDetail: {
      category: "Core Ordering",
      action: "Product Clicked",
      label: products.map(product => product.name).join(", "),
      ...additionalDetails
    }
  });
};

// Event for tracking Views of Product Details
// Follows Google's Enhanced Ecommerce: https://developers.google.com/tag-manager/enhanced-ecommerce#details
// Also includes generic event information
export const trackProductDetailView = (
  products: GTMProduct[],
  label: string
) => {
  analytics.track("product_detail_viewed", {
    ecommerce: {
      detail: {
        products: products.map(product => ({
          ...product,
          brand: "Offlimits",
          category: "All"
        }))
      }
    },
    eventDetail: {
      category: "Core Ordering",
      action: "Product Detail Viewed",
      label
    }
  });
};

export const trackProductListViewed = (
  products: GTMProduct[],
  {
    id,
    category
  }: {
    id: string;
    category: string;
  }
) => {
  analytics.track("product_list_viewed", {
    ecommerce: {
      detail: {
        products: products.map(product => ({
          ...product,
          brand: "Offlimits",
          category: "All"
        }))
      }
    },
    eventDetail: {
      category: "Core Ordering",
      action: "Product List Viewed",
      label: `${id}_${category}`
    }
  });
};

// Event for tracking Additions to a Shopping Cart
// Follows Google's Enhanced Ecommerce: https://developers.google.com/tag-manager/enhanced-ecommerce#add
// Also includes generic event information
export const trackAddToCart = (
  product: GTMProduct,
  quantity: number,
  shopifyCart: ShopifyCart
) => {
  console.log("track add...", product);
  analytics.track("product_added", {
    ecommerce: {
      cart_id: shopifyCart.id,
      currencyCode: "US",
      add: {
        products: [
          { ...product, quantity, brand: "Offlimits", category: "All" }
        ]
      }
    },
    eventDetail: {
      category: "Core Ordering",
      action: "Product Added",
      label: `${product.name} -- ${product.variant}`
    }
  });
};

// Event for tracking Additions to a Shopping Cart
// Follows Google's Enhanced Ecommerce: https://developers.google.com/tag-manager/enhanced-ecommerce#add
// Also includes generic event information
export const trackRemoveFromCart = (
  product: GTMProduct,
  quantity: number,
  shopifyCart: ShopifyCart
) => {
  analytics.track("product_removed", {
    ecommerce: {
      currencyCode: "usd",
      cart_id: shopifyCart.id,
      remove: {
        products: [
          { ...product, quantity, brand: "Offlimits", category: "All" }
        ]
      }
    },
    eventDetail: {
      category: "Core Ordering",
      action: "Product Removed",
      label: `${product.name} -- ${product.variant}`
    }
  });
};

// Event for Shopify Cart view
// Not sure this is in enhanced ecommerce but it is in our tracking plan
export const trackCartView = (shopifyCart: ShopifyCart) => {
  analytics.track("cart_viewed", {
    ecommerce: {
      view: {
        cart_id: shopifyCart.id,
        products: shopifyCart.lines.edges.map(({ node: product }) => ({
          name: product.merchandise.product.title,
          id: product.id,
          price: parseFloat(product.merchandise.price.amount),
          brand: "Offlimits",
          category: "All",
          variant: product.merchandise.title,
          quantity: product.quantity
        }))
      }
    },
    eventDetail: {
      category: "Core Ordering",
      action: "Cart Viewed",
      label: ``
    }
  });
};

// Event for tracking Generic Events
// Structure follows expected values for Google Analytics
export const eventTracked = (
  category: string,
  action: string,
  label: string,
  value?: number
) => {
  analytics.track("event_tracked", {
    eventDetail: {
      category,
      action,
      label,
      value
    }
  });
};

export default analytics;
