import React, { useEffect, useState } from 'react'
import Helmet from 'react-helmet'
import cx from 'classnames'

import { SwitchTransition, Transition } from 'react-transition-group'
import { Header } from "src/components/header"
import { Footer } from 'src/components/footer'
import { CartDrawer } from "src/components/cartDrawer"

import "src/styles/main.css"

const TRANSITION_DURATION = 400;
const TRANSITION_STYLES = {
	default: {
		transition: `opacity ${TRANSITION_DURATION}ms ease-in-out`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const Layout = ({ children, location, pageContext }: { children: any, location: any }) => {
  const [showPromoBar, setShowPromoBar] = useState(true)
  useEffect(() => {
    // const ga = window.ga || []
    // if (ga) {
    //   ga('require', 'linker')
    //   ga('linker:autoLink', ['shop.eatofflimits.com']);
    // }
    console.groupCollapsed('Site credits 🍝')
    console.log('Development by Kevin Green https://ctrlaltdel.world')
    console.log('Design by Sam Faulkner http://www.sam-faulkner.com/')
    console.groupEnd()
  }, [0])

  return (
    <React.Fragment>
      <div style={{ backgroundImage: 'url(/global_bg.png'}} className='fix opacity-10 top left w-full h-full z-40 pointer-events-none user-select-none' />
      <Helmet title='Offlimits'>
        <meta name="google-site-verification" content="88NKzfR9PZGG5-P4zEzEhkfjxmJ-N2WxftmUrPbk5EY" />
        <script async src="https://app.novel.com/vendor/js/novel-storefront.js?shop=offlimits-cereal.myshopify.com"></script>
        <script>{`
          !function (w, d, t) {
            w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
          
            ttq.load('C65TLIOQCDCUAMIVCQLG');
            ttq.page();
          }(window, document, 'ttq');
        `}</script>
      </Helmet>
      <div className={cx('bg-yellow main-site js-site', location.pathname.replace('/', ''), {
        'bump-down': showPromoBar && pageContext.config && pageContext.config.header && pageContext.config.header.showPromo
      })}>
        <a name='maincontent' className='fix top left z10 skip' href="#maincontent">Skip to main content</a>
        <CartDrawer upsell={pageContext.config ? pageContext.config.cart.cartUpsell : null} />
        <Header {...pageContext.config} hideBar={() => setShowPromoBar(false)} />
        {!/account/.test(location.pathname) ? (
          <React.Fragment>
            {children}
            {pageContext.config && (<Footer {...pageContext.config} />)}
          </React.Fragment>
        ) : (
          <div>
            {children}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default Layout