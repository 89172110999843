import React, { useState } from 'react'
import FocusTrap from 'focus-trap-react'
import Draggable from 'react-draggable'

import {
  Logo,
  Snapchat,
  TikTok,
  Youtube,
  Facebook,
  Twitter,
  Close,
  Instagram
} from 'src/components/svgs'

import { Newsletter } from 'src/components/newsletter'
import { InstagramBlock } from 'src/components/global/instagram'
import { PageLink } from 'src/components/link'
import { MarioStar } from 'src/components/star'

export const Footer = ({ instagram }) => {
  const [credits, setCredits] = useState(false)
  const date = new Date()

  const trap = credits ?
    <FocusTrap>
      <Draggable handle='.js-header'>
        <div className='credits__box fix z10 ba box-shadow x'>
        <button type='reset' className='bcy close draggable__close abs p05 bab z2 cb' onClick={() => setCredits(false)}>
              <Close className='block' />
            </button>
          <div className='cart__drawer-header  p1 bcp f jcb aic js-header'>
            <span className='s24'>Credits</span>
            
          </div>
          <div className='credits__box bcpattern al p1'>
            <MarioStar className='credits__star right bottom p1' starName='siteCredits' />
            Web Development - <a href='https://ctrlaltdel.world' target='_blank'>Kevin Green</a><br />
            Digital Design - <a href='http://www.sam-faulkner.com/' target='_blank'>Sam Faulkner</a><br />
            Branding & Packaging - <a href='https://pentagram.com' target='_blank'>Pentagram</a><br />
            Character Design - <a href='http://www.studionumberone.com/'>Studio Number One</a>
          </div>
        </div>
      </Draggable>
    </FocusTrap>
  : false


  return (
    <div className='overflow-hidden'>
      {trap}
      <InstagramBlock {...instagram} />
      <footer className='footer rel bcb'>
        <div className='container--xl mxa  cw  footer__wrapper'>
          <div className='text-left p-5 800:pl-8 800:pr-8'>
            <span className='mono text-14 uppercase'>Shop Our Cereal</span>
            <div className='footer__cereal text-left grid grid-cols-2 800:block'>
            <div>
                <PageLink to='/products/maple-pancake-vanilla-marshmallow-cereal' className='caps text-48 mt-2 leading-40 800:text-100 800:leading-100 text-teal cy offlimits inline-block m0 p0'>FLUF n PUF</PageLink>
              </div>
              <div>
                <PageLink to='/products/dash-coffee-cereal-large-box' className='caps text-48 mt-2 leading-40 800:text-100 800:leading-100 text-yellow cy offlimits inline-block m0 p0'>Dash</PageLink>
              </div>
              <div>
                <PageLink to='/products/zombie-pandan-cereal-large-box' className='caps text-48 mt-2 800:mt-0 leading-40 800:text-100 800:leading-100 text-zombie cp offlimits inline-block m0 p0'>Zombie</PageLink>
              </div>
              <div>
                <PageLink to='/products/flex-cinnamon-cereal-large-box' className='caps text-48 mt-2 800:mt-0 leading-40 800:text-100 800:leading-100 text-blue cp offlimits inline-block m0 p0'>Flex</PageLink>
              </div>
              <div>
                <PageLink to='/products/spark-strawberry-cereal-large-box' className='caps text-48 mt-2 800:mt-0 leading-40 800:text-100 800:leading-100 text-pink cp offlimits inline-block m0 p0'>Spark</PageLink>
              </div>
            </div>
          </div> 
          <div className='w-full p-5  '>
            <div className='w-full grid grid-cols-2 800:grid-cols-1 800:border-b-0  pb-10 border-b-2 border-dashed border-white mb-10' >
              <div className='hidden 800:block'>
                <span className='mono uppercase text-14 inline-block mb-2'>Info</span>
              </div>
              <div className=''>
                <PageLink to='/about' className='text-20 800:text-24 mb-3 block 800:mb-1'>About Us</PageLink>
                <PageLink to='/toy-store' className='text-20 800:text-24 mb-3 block 800:mb-1'>Toy Store</PageLink>
                <a href='https://storerocket.io/embed/Yw8lORjJvo' target='_blank' className='text-20 800:text-24 mb-3 block 800:mb-1'>Store Locator</a>
                <a href='mailto:sales@eatofflimits.com' target='_blank' className='text-20 800:text-24 mb-3 block 800:mb-1'>Wholesale</a>
                <PageLink to='/press' className='text-20 800:text-24 mb-3 block 800:mb-1'>Press</PageLink>
              </div>
              <div className=''>
                <a href='mailto:yo@eatofflimits.com' className='text-20 800:text-24 mb-3 block 800:mb-1'>Contact Us</a>
                <PageLink to='/frequently-asked-questions' className='text-20 800:text-24 mb-3 block 800:mb-1'>FAQs</PageLink>
                {/* <PageLink to='/account/toy-store' className='s24 block'>Account</PageLink> */}
                {/* <PageLink to='/account/subscriptions' className='s24 block'>Subscriptions</PageLink> */}
              </div>
            </div>
          </div>
          <div>
            <div className='footer__newsletter cw mxa 1000:mt-2'>
              <h4 className='text-24 800:text-30'>Subscribe to receive updates from our emotionally unstable cartoons. </h4>
              <Newsletter arrowClass='cw' />
              <div className='footer__social ac justify-center 800:justify-start flex py1'>
                <a className='m05 mr-0 w-5 h-auto' href='https://www.tiktok.com/@offlimits?lang=en' target='_blank' rel='noopener'><TikTok /></a>
                <a className='m05 w-5 h-auto' href='https://www.youtube.com/channel/UCrRxt7enVzxOI4x6yfVh_Dw' target='_blank' rel='noopener'><Youtube /></a>
                <a className='m05' href='https://www.instagram.com/offlimits' target='_blank' rel='noopener'><Instagram /></a>
                <a href="https://twitter.com/offlimits" target="_blank" rel='noopener' className="m05"><Twitter /></a>
                <MarioStar starName='socialStar' className='footer__social-star' />
              </div>
              <div className='footer__legal f jcc py1'>
                <span className='small block o5 m05'>&copy; Offlimits {date.getUTCFullYear()}</span>
                <PageLink to='/privacy-policy' className='small block o5 m05'>Privacy Policy</PageLink>
                <PageLink to='/terms-of-service' className='small block o5 m05'>Terms of Service</PageLink>
                <button onClick={() => setCredits(!credits)} className='small rel block o5 b-unset cw m05'>Site Credits</button>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}
