import { find } from 'lodash'

interface CustomAttribute {
  key: string,
  value: string,
}

interface RechargeItem {
  customAttributes: any[],
  quantity: number,
  variant: {
    id: string,
  },
}

const hasAttribute = (collection: CustomAttribute[], attribute: string) => (
  find(collection, ({ key }) => (
    key === attribute
  )) ? true : false
)

const hasStar = (collection: any, attribute: string) => {
  return find(collection, ({ key }) => (
    key === attribute
  )) ? true : false
}

const getAttribute = (collection: any, attribute: string) => (
  find(collection, ({ attrs: { key } }) => (
    key === attribute
  ))
)

const hasSubscriptions = (items: RechargeItem[]) => {
  return items.some( (item: RechargeItem) => hasAttribute(item.attributes, '_isSubscription'))
}

const hasToys = (items: any) => {
  return items.some( (item: any) => hasAttribute(item.attributes, '_isToy'))
}

const toyCount = (items: any) => {
  return items.reduce(
    (runningTotal: number, item: any) => {
      if (hasAttribute(item.attributes, '_isToy')) {
        return item.quantity + runningTotal
      }
    },
    0
  )
}


export {
  hasAttribute,
  getAttribute,
  hasSubscriptions,
  toyCount,
  hasToys,
  hasStar,
}