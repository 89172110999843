import React, { useState, useEffect } from "react";
import cx from "classnames";
import Draggable from "react-draggable";
import FocusTrap from "focus-trap-react";
import { find, min, max } from "lodash";
import { shopify as storefront, globalID } from "src/shopify/client";
import { productQuery } from "src/shopify/queries";

import { AddButton } from "src/components/product/addButton";

import { Image } from "src/components/image";
import { trackCartView } from "src/components/analytics";

import { PageLink } from "src/components/link";

import { Close, Star } from "src/components/svgs";

import { LineItem } from "src/components/cart/lineItem";

import {
  useCartTotals,
  useCartItems,
  useCheckout,
  useCartCount,
  useStore,
  useCartTickets,
  useUpdateItemsFromCart,
  usePage,
  useToggleCart,
  useRecharge,
  useAddItemToCart
} from "src/context/siteContext";

import {
  hasAttribute,
  hasToys,
  toyCount,
  getAttribute,
  hasSubscriptions
} from "src/utils/items";

const ToySelect = ({ toys }) => {
  const addItemToCart = useAddItemToCart();
  const cartTickets = useCartTickets();
  const { customerTickets } = useStore();
  const [soldOut, setSoldOut] = useState("");
  const avaTickets = cartTickets + customerTickets;
  const addToy = toy => {
    storefront
      .fetch(productQuery, {
        id: globalID("Product", toy.content.shopify.productId)
      })
      .then(data => {
        const product = data.product;
        const variants = product?.variants.edges;
        const defaultVariant = variants?.[0].node;

        if (!defaultVariant.availableForSale) {
          setSoldOut(toy.content.shopify.productId);
        }

        if (!defaultVariant.availableForSale) {
          const customAttributes = [
            { key: "_subTitle", value: toy.content.main.subTitle },
            { key: "_image", value: toy.content.main.image },
            { key: "_ticketAdd", value: `-${toy.content.main.ticketValue}` },
            { key: "_isToy", value: `true` }
          ];
          addItemToCart(defaultVariant.id, 1, customAttributes).then(() => {
            console.log("Product Added!");
          });
        }
      });
  };
  const checkInventory = toy => {
    if (window.PRODUCT_INVENTORY) {
      const currentItem = window.PRODUCT_INVENTORY.filter(value => {
        return value.id === toy.content.shopify.defaultVariant.variantId;
      });
      if (currentItem[0] && !currentItem[0].available) {
        return true;
      }
    }
  };
  return (
    <div className="cart__toys">
      {toys.map(toy => (
        <div className="x  cart__toys-items rel" key={toy._id}>
          {checkInventory(toy) && (
            <div className="abs x y top s14 founders bold caps left ac f jcc aic">
              <span>{toy.content.main.toyOutOfStock || "Coming Soon!"}</span>
            </div>
          )}
          <button
            onClick={() => addToy(toy)}
            disabled={avaTickets < toy.content.main.ticketValue}
            className={cx("cart__toy-item ac bcw f jcb aic", {
              "sold-out": checkInventory(toy)
            })}
          >
            <div className="al">
              <span className="s20 cart__toy-name x  pb05 z1">
                {toy.content.main.title}
              </span>
              <span className="s20 cart__toy-tickets pt05 jcs  aic x f">
                {toy.content.main.ticketValue}
                <img
                  src="/mini-ticket.png"
                  className="ml05"
                  alt="ticket image"
                />{" "}
              </span>
            </div>
            <img
              className="x cart__toy-image inline-block x "
              src={toy.content.main.image}
              alt={toy.content.main.title}
            />
          </button>
        </div>
      ))}
    </div>
  );
};

export const CartDrawer = ({ upsell }) => {
  const [aTickets, setATickets] = useState(0);
  const cartTickets = useCartTickets();

  const updateItemsFromCart = useUpdateItemsFromCart();
  const { customerTickets, cartToys, checkout, cart } = useStore();

  const lineItems = useCartItems();

  // REMOVE THESE WINDOWS
  const [toys, setToys] = useState(false);
  const [combo, setCombo] = useState(false);
  const [shipping, setShipping] = useState(false);

  const { total } = useCartTotals();
  const { page } = usePage();
  const { cartIsOpen } = useStore();
  const toggleCart = useToggleCart();
  const openCheckout = useCheckout();
  const openRecharge = useRecharge();

  const count = useCartCount();
  const withToys = hasToys(lineItems);
  const toyAmount = toyCount(lineItems);

  const checkForSubscriptions = () => {
    const withSubscriptions = hasSubscriptions(lineItems);

    withSubscriptions ? openRecharge() : openCheckout();
  };

  let containsUpsell = false;
  if (upsell) {
    lineItems.forEach(item => {
      if (item.title === upsell.content.main.title) {
        containsUpsell = true;
      }
    });
  }

  // useEffect(() => {
  //   if (cartIsOpen) {
  //     setToys(true)
  //     setCombo(true)
  //   }
  // }, [cartIsOpen])

  const upsellItem = upsell && !containsUpsell && (
    <div>
      <div className="p-4 border-b-2 border-solid">
        <div className="grid grid-cols-10 gap-4">
          <div className="col-span-3">
            <div
              className={cx(
                " border-2 border-black h-24 w-24 flex items-center justify-center bg-pink"
              )}
            >
              <Image
                imageId={upsell.content.main.singleImage.asset._id}
                className="w-full"
                alt={upsell.content.main.cleanTitle}
              />
            </div>
          </div>
          <div className="col-span-7">
            <h4 className="text-24 offlimits uppercase">
              Add {upsell.content.main.cleanTitle}
            </h4>
            <p className="mt-2">{upsell.content.main.upsellDescription}</p>
            <AddButton
              {...upsell.content.shopify}
              buttonClass="uppercase underline text-14 text-black mt-2"
              color={upsell.content.main.colorAssociation}
              sub={upsell.content.main.subTitle}
              image={upsell.content.main.singleImage.asset._id}
            />
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    if (cartIsOpen) trackCartView(cart);
  }, [cartIsOpen]);

  useEffect(() => {
    setATickets(customerTickets + cartTickets);
  }, [cartTickets, customerTickets]);
  useEffect(() => {
    // Remove toys from the cart if the tickets drop in the negative
    if (aTickets < 0) {
      if (lineItems.length > 0) {
        lineItems.forEach(({ id, attributes }) => {
          if (hasAttribute(attributes, "_isToy")) {
            updateItemsFromCart({ id, quantity: 0 });
          }
        });
      }
    }
  }, [aTickets]);
  const trap = cartIsOpen ? (
    <FocusTrap
      focusTrapOptions={{
        onDeactivate: !cartIsOpen
      }}
    >
      <div className="cart__drawer-wrapper mxa abs x y">
        <div className="absolute w-full h-full top left" onClick={toggleCart} />
        {toys && (
          <Draggable handle=".js-toy">
            <div className="cart__toy abs ba box-shadow x">
              <div className="cart__drawer-header  p1 bg-orange f jcb aic js-toy">
                <span className="s24">Your Cart</span>
              </div>
              <button
                type="reset"
                className="bcy close abs z2 m1 top right p05 bab z2 cb"
                onClick={() => setToys(false)}
              >
                <Close className="block" />
              </button>
              {lineItems.length > 0 ? (
                <div className="rel z1 px1 pb1 cart__toy-select ac jcc f">
                  <div>
                    <h3 className="caps offlimits">Pick a Toy</h3>
                    <span className="caps s14 founder bold">
                      You have {aTickets} Tickets
                    </span>
                    <ToySelect toys={cartToys} />
                    <div>
                      <h4 className="s14 caps mt1 block pt1 founders">
                        Or forget the toys and
                        <br />
                        keep the tickets!
                      </h4>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="abs z1 x y top left ac f jcc aic p1">
                  <h4 className="offlimits caps">
                    Toys come with
                    <br />
                    Every Set!
                  </h4>
                </div>
              )}
              <img
                className="cart__combo-image abs x y top block x"
                src="/cart_toys.png"
                alt="Toys"
              />
            </div>
          </Draggable>
        )}
        {combo && (
          <Draggable handle=".js-combo">
            <div className="cart__combo abs ba bcw box-shadow x">
              <button
                type="reset"
                className="bcy  abs  draggable__close top right p05 bab z2 cb"
                onClick={() => setCombo(false)}
              >
                <Close className="block" />
              </button>
              <div className="js-combo">
                <img
                  className="cart__combo-image block p1 c-s x"
                  src="/cart_combo.png"
                  alt="Characters spinning"
                />
              </div>
            </div>
          </Draggable>
        )}
        {/* shipping && (
          <Draggable handle='.js-shipping'>
            <div className='cart__shipping abs bcw box-shadow x'>
              <button type='reset' className='bcy close p05 draggable__close bab abs top right z2 cb' onClick={() => setShipping(false)}>
                  <Close className='block' />
                </button>
              <div className='cart__drawer-header  p1 bcp f jcb aic js-shipping'>

                {lineItems.length <= 0 || parseInt(total) < 48 ? (
                  <span className='s24'>Is Shipping Free Yet?</span>
                ): (
                  <span className='s24'>Shipping is Free!</span>
                )}
              </div>
              <div className='cart__drawer-shipping p1  rel'>
                {lineItems.length <= 0 || parseInt(total) < 48 ? (
                  <div className='x ac'>
                    <div className='ss12'>Add <span className='bold'>${(48 - (parseFloat(total))).toFixed(2)}</span> for Free Shipping</div>
                    <div className={cx('cart__drawer-progress x rel', {
                      'bg-gray': lineItems.length > 0
                    })}>
                      <span className='block abs bcblack y' style={{ 'width': `${((parseFloat(total)).toFixed(2) / 48) * 100}%` }} />
                    </div>
                  </div>
                ) : (
                  <div className='cart__drawer-shipping-active'>
                    <div className='offlimits s24 rel z2 caps'>Your order qualifies for <span className='bold'>Free Shipping!</span></div>
                    <img src='/dash_c.png' className='x cart__drawer-cereal c-s abs' alt='Floating Cereal' />
                    <img src='/dash_c.png' className='x cart__drawer-cereal c-s abs' alt='Floating Cereal' />
                    <img src='/zombie_c.png' className='x cart__drawer-cereal c-s abs' alt='Floating Cereal' />
                    <img src='/zombie_c.png' className='x cart__drawer-cereal c-s abs' alt='Floating Cereal' />
                  </div>
                )}
              </div>
            </div>
          </Draggable>
                )*/}
        <div className="cart__drawer  z-10 box-shadow x">
          <button
            type="reset"
            className="bcy close p05 draggable__close bab abs top right z2 cb"
            onClick={() => toggleCart()}
          >
            <Close className="block" />
          </button>
          <div className="cart__drawer-header  p1 bg-orange f jcb aic js-header">
            <span className="s24">Your Cart</span>
          </div>
          {lineItems.length > 0 && (
            <div className="bg-white p-4 border-b-2 border-black text-center">
              <div className="">
                {lineItems.length <= 0 || parseInt(total) < 48 ? (
                  <span className="mono text-14 uppercase">
                    Spend $50 and get Free Shipping
                  </span>
                ) : (
                  <span className="mono text-14 uppercase">
                    Shipping is Free!
                  </span>
                )}
              </div>
              <div
                className={cx("cart__drawer-progress x rel", {
                  "bg-gray": lineItems.length > 0
                })}
              >
                <span
                  className="block abs bcblack y"
                  style={{
                    width: `${(parseFloat(total).toFixed(2) / 48) * 100}%`
                  }}
                />
              </div>
            </div>
          )}
          <div className="cart__drawer-items p1 bcpattern">
            {lineItems.length > 0 ? (
              <div>
                {lineItems.map(({ node }) => (
                  <React.Fragment key={node.id}>
                    <LineItem key={node.id} toys={withToys} {...node} />
                  </React.Fragment>
                ))}
              </div>
            ) : (
              <div>
                <img
                  className="cart__drawer-empty"
                  src="/cart_empty.png"
                  alt="Empty Cart"
                />
              </div>
            )}
          </div>
          <div className="bcw  cart__drawer-footer fixed bottom-0 w-full 800:absolute">
            {upsellItem}
            <div className="p-6">
              <div className="x jcb  pb-4">
                {/* <div className='x f jcb aic pt0 pb0b'>
                  <span className='s24'>Tickets</span>
                  <span className='s24 cart__single-ticket'><img src='/mini-ticket.png' className='mr05' alt='ticket image' />  {aTickets}</span>
                </div> */}
                {/* checkout.customAttributes.length > 0 && (
                  <div className='f jcb aic'>
                    <span className='s24'><Star className='' /> Star Bonus</span>
                    <span className='s24'>+{checkForStars()}</span>
                  </div>
                ) */}
                {/* lineItems.length > 0 && (
                  <div className='cart__single-toys-mobile mt05'>
                    <div className='al'>
                      <h4 className='s14 caps'>Pick a Toy</h4>
                    </div>
                    <ToySelect toys={cartToys} />
                  </div>
                ) */}
                {lineItems.length > 0 && (
                  <React.Fragment>
                    <div className="flex justify-between mono uppercase text-14">
                      <span className="">Subtotal</span>
                      <span className="">${cart.cost.subtotalAmount.amount}</span>
                    </div>
                  </React.Fragment>
                )}
              </div>
              {lineItems.length < 1 || toyAmount === count ? (
                <PageLink
                  className="button box-shadow-sm ac button--black text-white w-full block pl-8 pr-8 s20 button--border"
                  onClick={() => toggleCart()}
                  to="/collections/all"
                >
                  <span className="">Shop Cereal</span>
                </PageLink>
              ) : (
                <a
                  rel="external"
                  target="_blank"
                  href={cart.checkoutUrl}
                  className="button box-shadow-sm ac button--black text-white w-full block pl-8 pr-8 s20 button--border"
                >
                  <span className="">Checkout</span>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  ) : (
    false
  );
  return (
    <div
      className={cx("fix x y top left z10 cart", page, {
        "is-open": cartIsOpen
      })}
    >
      {trap}
    </div>
  );
};
