const request = async ({ headers, url }, query, variables) => {
  try {
    const response = await fetch(url.toString(), {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Shopify-Storefront-Access-Token": 'acb63d1b38a04ec68a1559935e7aee1b'
      },
      body: JSON.stringify({
        query: minifyQuery(query),
        variables
      })
    });

    const data = await response.json();

    return data;
  } catch (e) {
    console.error(e);
  }
};

const minifyQuery = string => {
  return string
    .replace(/\s*#.*$/gm, "") // Remove GQL comments
    .replace(/\s+/gm, " ") // Minify spaces
    .trim();
};

export const createShopifyClient = client => {
  const version = client.version || "2023-07";
  const store = client?.store;
  const publicToken = client?.storefrontToken;

  if (!store) {
    throw error(500, "Missing Store");
  }

  if (!publicToken) {
    throw error(500, "Missing Token");
  }

  const url = new URL(`https://${store}/api/${version}/graphql.json`);

  return {
    fetch: async (query, input) => {
      const variables = {
        ...input
      };

      const response = await request({ url }, query, variables);

      return response?.data;
    }
  };
};

export const shopify = createShopifyClient({
  store: "offlimits-cereal.myshopify.com",
  storefrontToken: "acb63d1b38a04ec68a1559935e7aee1b"
});

export const globalID = (type, id) => {
  return `gid://shopify/${type}/${id}`;
};
