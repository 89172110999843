
export const gql = (chunks, ...variables) => {
	return chunks.reduce(
		(accumulator, chunk, index) =>
			`${accumulator}${chunk}${index in variables ? variables[index] : ``}`,
		``
	);
};

export const productQuery = gql`
  query getProduct($id: ID!) {
    product(id: $id) {
      availableForSale
      createdAt
      description
      descriptionHtml
      handle
      productType
      publishedAt
      updatedAt
      onlineStoreUrl
      totalInventory
      options(first: 3) {
        id
        name
        values
      }
      id
      title
      featuredImage {
        id
        url
      }
      images(first: 20) {
        edges {
          node {
            id
            url
            altText
            width
            height
          }
        }
      }
      variants(first: 100) {
        edges {
          node {
            id
            title
            image {
              url
              width
              height
            }
            title
            compareAtPrice {
              amount
            }
            price {
              amount
            }
            sku
            weight
            availableForSale
            currentlyNotInStock
            quantityAvailable
          }
        }
      }
    }
  }
`

const cart = gql`
  {
    totalQuantity
    id
    createdAt
    updatedAt
    checkoutUrl
    discountCodes {
      applicable
      code
    }
    attributes {
      key
      value
    }
    discountAllocations {
      discountedAmount {
        amount
      }
    }
    note
    lines(first: 250) {
      edges {
        node {
          id
          attributes {
            key
            value
          }
          quantity
          cost {
            amountPerQuantity {
              amount
            }
            compareAtAmountPerQuantity {
              amount
            }
            subtotalAmount {
              amount
            }
            totalAmount {
              amount
            }
          }
          discountAllocations {
            discountedAmount {
              amount
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              title
              compareAtPrice {
                amount
              }
              price {
                amount
              }
              quantityAvailable
              image {
                width
                height
                url
              }
              product {
                title
                handle
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
    cost {
      subtotalAmount {
        amount
      }
      totalAmount {
        amount
      }
      totalDutyAmount {
        amount
      }
      totalTaxAmount {
        amount
      }
    }
  }
`

// Get Cart by Id
export const cartQuery = gql`
	query getCart($id: ID!) {
		cart(id: $id) ${cart}
	}
`

// Creates a new cart.
export const cartCreateQuery = gql`
	mutation cartCreate($input: CartInput!) {
		cartCreate(input: $input) {
			cart ${cart}
		}
	}
`

// Adds a merchandise line to the cart.
export const cartLinesAddQuery = gql`
	mutation addItemToCart($cartId: ID!, $lines: [CartLineInput!]!) {
		cartLinesAdd(cartId: $cartId, lines: $lines) {
			cart ${cart}
		}
	}
`

// Updates a merchandise line to the cart.
export const cartLinesUpdateQuery = gql`
	mutation cartLinesUpdate($cartId: ID!, $lines: [CartLineUpdateInput!]!) {
		cartLinesUpdate(cartId: $cartId, lines: $lines) {
			cart ${cart}
		}
	}
`

// Removes a merchandise line to the cart.
export const cartLinesRemoveQuery = gql`
	mutation removeItemFromCart($cartId: ID!, $lineIds: [ID!]!) {
		cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
			cart ${cart}
		}
	}
`

export const cartAttributesUpdate = gql`mutation cartAttributesUpdate($attributes: [AttributeInput!]!, $cartId: ID!) {
  cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
    cart ${cart}
    userErrors {
      field
      message
    }
  }
}`

export const cartNoteUpdate = gql`mutation cartNoteUpdate($cartId: ID!, $note: String!) {
  cartNoteUpdate(cartId: $cartId, note: $note) {
    cart ${cart}
    userErrors {
      field
      message
    }
  }
}`
