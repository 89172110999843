import cx from "classnames"
import Img from "gatsby-image"
// @ts-ignore
import { useIntersection } from 'use-intersection'
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import React, { useState, useEffect } from "react"

const sanityConfig = { projectId: "0z8flqaa", dataset: "production" }

export const Image = ({ imageId, className, width, alt, src, onClick, style, dataTickle }: {
  imageId?: string
  width?: number
  alt?: string
  dataTickle?: number
  onClick?: any
  style: any
  className?: string
  src?: string
}) => {
  const [mounted, setMounted] = useState(true) // FOR FUTURE
  const [loaded, setLoaded] = useState(false)
  const [visible, setVisible] = useState(false) // FOR FUTURE
  let fluidProps

  if (imageId && !/gif/.test(imageId)) {
    fluidProps = getFluidGatsbyImage(imageId, { maxWidth: width || 2400 }, sanityConfig)
  }

  return (
    <div onClick={onClick} style={style} className={cx("image__block ", className)} data-tickl={dataTickle}>
      {fluidProps ? (
        <React.Fragment>
          <Img className='hidden__image' fluid={fluidProps} alt={alt} defaultFadeIn={700} />
        </React.Fragment>
      ) : (
          <img
            alt={alt}
            src={mounted ? src : undefined}
            className={cx("x y block", {
              "is-loaded": loaded,
              "is-visible": visible
            })}
            onLoad={() => {
              setLoaded(true)
            }} />
        )}
    </div>
  )
}
