import React, { useState, useEffect } from "react";
import { find } from "lodash";
import { hasAttribute, getAttribute } from "src/utils/items";
import cx from "classnames";

import { Close } from "src/components/svgs";

import {
  useUpdateItemsFromCart,
  useRemoveItemFromCart
} from "context/siteContext";

export const LineItem = ({
  id,
  quantity,
  merchandise: {
    price,
    product: { title }
  },
  attributes
}) => {
  const updateItemsFromCart = useUpdateItemsFromCart();

  const [stateQuantity, setQuantity] = useState(quantity);
  const removeFromCart = useRemoveItemFromCart();

  const updateQuantity = (qty: number) => {
    updateItemsFromCart({ id, quantity: qty, attributes });
    setQuantity(qty);
  };

  return (
    <div className="grid w-full rel  grid-cols-10 pt-2 pb-2 border-b-2 border-dashed ">
      <div className="cart__single-image mr1 col-span-3  ">
        <div
          className={cx(
            " border-2 border-black h-24 w-24 flex items-center justify-center",
            attributes[2] && attributes[2].value
          )}
        >
          <img
            className="h-20 object-contain w-full"
            src={`${attributes[1].value}?h=202`}
            alt={title}
          />
        </div>
      </div>
      <div className="col-span-7 ">
        <React.Fragment>
          <span className="text-30 ls1 caps m0 p0 w-8/12 offlimits">
            {title}
          </span>
          {/* <p className='m0 p0 caps s14 founders bold'>{attributes[0].value}</p> */}
        </React.Fragment>
        <div className="flex items-center justify-between">
          <div className="max-w-30 flex items-center">
            <button
              className="block rel qty__control bctran no-style s24 f jcc aic founders cursor py05 aic"
              onClick={() =>
                stateQuantity === 1 ? updateQuantity(0) : updateQuantity(stateQuantity - 1)
              }
            >
              <img src="/qty_minus.png" alt="minus" />
            </button>
            <input
              type="number"
              value={stateQuantity}
              onChange={e =>
                updateQuantity(parseInt(e.currentTarget.value, 10))
              }
              name="quantity"
              min="1"
              className="cb founders mono m-2 text-14 w-8 card-qty border-2 ac"
            />
            <button
              className="qty__control bctran no-style s1 block f jcc aic founders s24 cursor rel py05 jcc aic"
              onClick={() => updateQuantity(stateQuantity + 1)}
            >
              <img src="/qty_plus.png" alt="plus" />
            </button>
          </div>
          <div className="text-16">
            ${(parseFloat(Number(price.amount)) * stateQuantity).toFixed(2)}
          </div>
        </div>
        <button
          type="reset"
          className="p05 abs bctran close right top cb"
          onClick={() => updateQuantity(0)}
        >
          <Close className="block" />
        </button>
      </div>
    </div>
  );
};
